input[type='text']:focus,
input[type='number']:focus,
input[type='search'],
select:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-number-input input[type='number']:focus {
    outline: none !important;
}

.custom-number-input button {
    -webkit-tap-highlight-color: #666;
}

.custom-number-input button:focus {
    outline: none !important;
    box-shadow: none !important;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}


.text-input {
    @apply mb-4 border rounded;
}

.label {
    @apply block text-gray-700 font-medium mb-2;
}

.end-of-list {
    @apply flex justify-center text-slate-500;
}


.nav-wrapper {
    @apply bg-white/0 h-12 items-center px-2 py-3 sticky top-0 z-20;
    transition: backgound 1s ease-out;
}

.normal-nav-wrapper {
    @apply flex transition-none bg-white;
    h1 {
        @apply flex-1 text-center pr-10;
    }
}

.header-wrapper {
    @apply flex h-14 items-center px-5 py-3 sticky top-0 z-20 bg-primary_color-0;
}

.swiper-pagination-fraction {
    right: 10px !important;
    left: unset !important;
    width: 40px !important;
    @apply  bg-black/40 rounded text-xs p-1 text-white lg:hidden
}