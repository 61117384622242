.secondary-bg {
    @apply bg-slate-100;
}

.cart-section-item {
    @apply mt-2 px-2 py-2 border-y bg-white;
}

.cart-item-wrapper {
    @apply bg-white flex flex-wrap justify-between;

    .product-price {
        @apply flex items-center;
        span {
            @apply p-2 pl-0;
        }
    }

    .item-info {
        @apply basis-[65%];
    }
}

.address-section {
    @apply bg-white flex flex-col;
    .info {
        @apply flex items-center;
        svg {
            @apply mr-1 text-orange-600;
        }
        .fullname {
            @apply font-medium;
        }
        .phone {
            @apply font-medium;
        }
        i {
            @apply inline-block w-[2px] h-[20px] bg-slate-300 mx-2;
        }
    }
    .address {
        @apply text-slate-600;
    }
    .default {
        @apply flex text-green-600 items-center text-sm my-1;
    }
}

.shipping-address {
    @apply bg-white flex justify-between items-center;
}

.add-address-section {
    @apply text-blue-800 bg-white flex justify-between items-center my-2 px-4 py-3;
}

.input {
    @apply border-b leading-8 text-slate-600;
}

.submit {
    @apply w-full p-3 my-4 font-normal m-auto block w-[90%] bg-secondary_color;
}

.payment {
    input[type='radio'] {
        @apply absolute opacity-0;
        -webkit-tap-highlight-color: transparent;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .payment-item {
        @apply border-b py-2;

        &:last-child {
            @apply border-0;
        }
        .title {
            @apply flex text-sm;
        }

        &.collapsed {
            .info {
                visibility: collapse;
                transition: all 0s linear;
                height: 0;
            }
        }

        .info {
            visibility: visible;
            height: auto;
            transition: all 0s linear;
            @apply pl-10;
        }

        .chevron {
            @apply ml-auto;
            svg {
                @apply h-5 w-5 text-gray-600;
            }
        }

        .title-prefix {
            @apply h-8 w-8 mr-2 stroke-1;
        }
    }
}

.price-summary {
    @apply mt-6 text-sm;

    .summary-item {
        @apply flex justify-between py-1;

        &.small {
            span {
                @apply text-sm text-gray-600;
            }
        }
    }
}

.infoItem {
    @apply flex px-2 py-2 items-center text-sm w-full ;
    .name {
        @apply font-medium basis-[40%] pr-2
    }
    .value {
        @apply flex-1
    }
}