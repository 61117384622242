@tailwind base;
@tailwind components;
@tailwind utilities;

/* Stop purging. */

/* Your own custom utilities */

body{
    @apply antialiased font-body scroll-smooth;
}


@import './cart.scss';
@import './common.scss';


